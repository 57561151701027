$bg: #002538;
$theme: #0077b8;

html {
  --color-mode: 'lit';
  --light: #fff;
  --dark: #000;
  --bg: #002538;
  --haze: #f2f2f2;
  --gray: #020202;
  --accent: var(--gray);
  --text: #575757;
  --header-text: var(--dark);
  --font: Metropolis, sans-serif;
  --theme: #0077b8;
  --ease: cubic-bezier(.19,1,.22,1);
  --code-bg: var(--bg);
  --table-bg: var(--light);
  --table-haze: var(--haze);
  --table-border: #dedede;
  --footer-bg: var(--haze);
  --shadow: rgba(0,0,0,0.12);
  --translucent: rgba(0,0,0,0.05);
  --translucent-light: rgba(255,255,255,0.05);
  --post-bg: var(--light);
  --choice-bg: var(--haze);
  --ease: cubic-bezier(0.39, 0.575, 0.565, 1);
  --easing: cubic-bezier(.19,1,.22,1);
  --notice-code-bg: var(--bg);
  --notice-info-border-color: #6AB0DE;
  --notice-info-background: #E7F2FA;
  --notice-note-border-color: #F0B37E;
  --notice-note-background: #FFF2DB;
  --notice-tip-border-color: rgba(92, 184, 92, 0.8);
  --notice-tip-background: #E6F9E6;
  --notice-warning-border-color: rgba(217, 83, 79, 0.8);
  --notice-warning-background: #FAE2E2; }
